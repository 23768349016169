@font-face {
  font-family: "Futura Light";

  font-style: normal;

  font-weight: 600 !important;

  src: url("./../../../fonts/FuturaStdLight.ttf");
}

* {
  /* color: #212121; */

  letter-spacing: 1px !important;
}

body {
  font-family: "Futura Light", sans-serif !important;

  background-color: #fff !important;

  padding: 0 !important;

  margin: 0 !important;

  overflow-x: hidden !important;
}

.navbar-col {
  padding: 0;
}

.navbar {
  background-color: transparent !important;
}

.navbar .navbar-toggler {
  border-color: transparent;
}

.navbar-brand {
  text-transform: capitalize;

  color: #ffffff !important;
}

.navbar-brand > img {
  height: 50px;

  object-fit: contain;
}

.navbar-link {
  padding: 0 10px !important;
}

.dropdown-toggle {
  color: #ffffff !important;
}

.navbar-scrolled {
  background-color: #ffffff !important;

  -webkit-box-shadow: 0 2px 3px -3px #999 !important;

  -moz-box-shadow: 0 2px 3px -3px #999 !important;

  box-shadow: 0 2px 3px -3px #999 !important;
}

.navbar-scrolled a {
  color: #212121 !important;
}

.navbar-scrolled > .navbar-brand {
  color: #212121 !important;
}

/* Landing Page Styles */
.landing-section {
  padding: 0 !important;

  min-height: 600px !important;

  /* background-color: red; */
}

.landing-section > img {
  width: 100%;

  height: 600px;

  object-fit: cover;
}

.landing-section > .overlay {
  display: flex;

  position: absolute;

  height: 100%;

  width: 100%;

  z-index: 1;

  background-color: rgba(0, 0, 0, 0.8);

  justify-content: center !important;

  align-items: center;
}

.landing-section .overlay img {
  height: 200px !important;

  object-fit: contain;
}

.carousel-item-img {
  height: 600px !important;

  object-fit: cover !important;
}

.nav-tabs .active {
  color: #f1592a !important;

  font-weight: bold !important;
}

.nav-tabs a {
  color: #212121 !important;
}

/* Footer Styles */
.footer {
  position: relative;

  bottom: 0px;

  background-color: #f1592a !important;

  /* min-height: 200px; */

  margin: 0 !important;

  padding: 1em 0 !important;

  width: 100% !important;
}

.footer p {
  font-size: 14px;

  text-transform: uppercase;

  font-weight: 900;
}

.footer h5 {
  padding: 0;

  margin: 0;
}

.footer ul {
  list-style: none;

  padding: 0;

  margin: 0;
}

.footer ul li {
  padding: 0;

  margin: 0;
}

.footer ul li a {
  font-size: 13px;

  color: #757575;

  padding: 0;

  margin: 0;
}

.footer .footer-item {
  display: flex;

  justify-content: center;

  align-items: center;

  flex-direction: column;
}

.footer .footer-item h6 {
  color: #fff !important;

  font-weight: 900 !important;
}

.footer .footer-item p {
  text-align: center;
}

#tab-heading {
  display: flex;

  flex-direction: row;

  justify-content: space-between;

  align-items: center;
}

#tab-heading p {
  display: none !important;
}

#bg-nested-dropdown {
  position: absolute !important;

  z-index: 1000 !important;

  overflow: unset;
}

.input-group .form-control,
.input-group-btn .btn {
  z-index: 1000 !important;
}

/* Media Queries */

/* Small devices (landscape phones, 576px and up) */
@media (max-width: 576px) {
  .nav-tabs a {
    color: #212121 !important;

    width: 100% !important;
  }

  #tab-heading p {
    display: block !important;
  }

  .nav-tabs .active {
    border: none !important;

    border-bottom: 2px solid #f8f8f8 !important;
  }

  .landing-section {
    width: 100% !important;

    height: 300px !important;

    min-height: 300px !important;

    /* background-color: yellowgreen !important; */
  }

  .landing-section > img {
    height: 300px !important;
  }

  .landing-section .overlay {
    height: 300px !important;
  }

  .landing-section .overlay img {
    height: 150px !important;

    object-fit: contain;
  }

  .landing-section-points {
    padding: 0 2em !important;

    min-height: 60vh;
  }

  .footer .footer-item {
    /* display: flex; */

    /* justify-content: flex-start; */

    align-items: flex-start;

    /* flex-direction: column; */
  }

  .footer .footer-item p {
    text-align: left;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (max-width: 768px) {
  .carousel-item-img {
    height: 300px !important;
  }

  .carousel-item h3 {
    font-size: 12px !important;
  }
}

/* Large devices (desktops, 992px and up) */
@media (max-width: 992px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (max-width: 1200px) {
}
