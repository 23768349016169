.react-datepicker-wrapper  {
  width: 100% !important;
}

.edit-bid-alert {
  color: red;
  text-align: center;
  padding: 10px 20px;
  background-color: rgba(255, 0, 0, 0.2);
}

.is-invalid {
  border-color: red;
}

.invalid-feedback {
  color: red;
  margin-top: 0.25rem;
  font-size: 80%;
}